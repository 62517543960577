'use client';

import { useSmoothScrollTo } from 'ui/hooks';
import ProductsGrid from '@lib/ui/components/products-grid';
import type { TranslationsDictionary } from '@lib/i18n';

export type Product = {
  _key: string;
  image?: {
    asset: {
      _type: string;
      _ref: string;
    };
    lqip: string;
  };
  isNew?: boolean;
  name?: string;
  productType: 'medicine' | 'supplement';
  shortDescription?: [];
  slug?: {
    current: string;
  };
};

type Props = {
  data?: {
    anchor?: string;
    bgColor?: string;
    headline?: string;
    subheadline?: [];
    products: Product[];
    warning?: [];
  };
  translationsDictionary: TranslationsDictionary;
};

export default function FeaturedProducts({ data, translationsDictionary }: Props): JSX.Element | null {
  const bind = useSmoothScrollTo(`#${data?.anchor}`);

  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-8 sm:py-8 lg:py-10 ${
        data?.bgColor ?? 'bg-white'
      }`}
      id={data?.anchor}
      {...(data?.anchor ? { ...bind } : null)}
    >
      <div className="flex flex-col w-full items-center px-4 md:px-10 lg:px-16">
        <h3 className="w-full pb-6 lg:pb-[3.375rem] font-display text-2xl lg:text-4xl text-black font-normal leading-[1.875rem] lg:leading-[2.75rem] text-left text-center max-w-screen-md">
          {data?.headline}
        </h3>
        <ProductsGrid products={data?.products} translationsDictionary={translationsDictionary} />
      </div>
    </section>
  );
}
