import { useState } from 'react';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    videos: {
      videoId: string;
      name: string;
      description: string;
      thumbnail: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
    }[];
  };
};

export default function YoutubeVideos({ data }: Props): JSX.Element {
  const [selectedVideo, setSelectedVideo] = useState(data.videos[0]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    video: {
      videoId: string;
      name: string;
      description: string;
      thumbnail: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
    }
  ): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      setSelectedVideo(video);
    }
  };

  return (
    <section className="flex justify-center w-full px-4 md:px-10 lg:px-16 pb-8 lg:pb-16 bg-white">
      <div className="flex flex-col lg:flex-row justify-center items-start w-full max-w-screen-lg">
        <div className="w-full lg:w-2/3 h-full">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full aspect-video border-0 h-full"
            key={selectedVideo?.videoId}
            src={`https://www.youtube.com/embed/${selectedVideo?.videoId}`}
            title="Embedded Youtube Video"
          />
        </div>
        <div className="flex flex-col w-full lg:w-1/3 p-5 lg:pl-6 bg-accent-2 h-full lg:pt-8 gap-y-4">
          {data.videos.map((video) => (
            <div
              className="cursor-pointer rounded flex items-center"
              key={video.videoId}
              onClick={() => {
                setSelectedVideo(video);
              }}
              onKeyDown={(event) => {
                handleKeyDown(event, video);
              }}
              role="button"
              tabIndex={0}
            >
              <img
                alt={video.name}
                className={`w-20 h-14 object-cover rounded ${selectedVideo?.videoId === video.videoId ? 'border-2 border-primary border-solid' : ''}`}
                src={urlForImage(video.thumbnail).url()}
              />
              <div className="ml-4">
                <h3 className="text-lg lg:text-2xl font-semibold font-display">{video.name}</h3>
                <p className="text-sm font-light">{video.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
