'use client';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { PortableText, toPlainText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import Image from 'next/image';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MinusCircleIcon, PlusCircleIcon } from '@public/icons';
import { urlForImage } from '@lib/sanity/image';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import theme from '../theme';

type Props = {
  data: {
    _type: string;
    _key: string;
    groups?: {
      _id: string;
      label: string;
      image: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      } | null;
      faqs?: {
        _id: string;
        question: string;
        answer: PortableTextBlock;
      }[];
    }[];
  };
  translationsDictionary: TranslationsDictionary;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`vertical-tab-${index}`}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <div className="lg:pl-20">{children}</div>}
    </div>
  );
}

function a11yProps(index: number): {
  id: string;
  'aria-controls': string;
} {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function GroupedFaqs({ data, translationsDictionary }: Props): JSX.Element | null {
  const [expanded, setExpanded] = useState<string | false>('');
  const [value, setValue] = useState(0);
  const t = useTranslation(translationsDictionary);

  function handleChange(_event: React.SyntheticEvent, newValue: number): void {
    setValue(newValue);
  }

  const handleChangeExpanded = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: data.groups?.map((faqGroup) => {
      return faqGroup.faqs?.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: toPlainText(faq.answer),
        },
      }));
    }),
  };

  return (
    <section className="flex flex-col w-full justify-center items-center pt-6 pb-3 lg:pt-16 px-6 md:px-10 lg:px-16">
      <script dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} type="application/ld+json" />

      <div className="flex flex-col lg:flex-row w-full justify-center max-w-screen-lg">
        <div className="flex flex-col">
          <Tabs
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            aria-label="fuca products"
            className="!overflow-visible"
            onChange={handleChange}
            orientation={useMediaQuery(theme.breakpoints.down('lg')) ? 'horizontal' : 'vertical'}
            value={value}
            variant="scrollable"
          >
            {data.groups?.map((element, index) => {
              return (
                <Tab
                  className={`mr-3 lg:mr-0 p-1 md:px-2 md:py-[0.4375rem] border border-solid mb-2 rounded-xl focus-visible:outline focus-visible:outline-secondary focus-visible:outline-2 focus-visible:outline-offset-[-2px] ${
                    index === value ? 'bg-accent-2 border-[#99B56B]' : 'border-[#D2D2D2]'
                  }`}
                  label={
                    <div className="text-lg w-[6.5rem] md:w-[9rem] lg:w-auto lg:min-w-[19rem] min-h-[6rem] lg:min-h-0 flex flex-col lg:flex-row justify-start">
                      {element.image ? (
                        <Image
                          alt={element.label}
                          className="rounded-md self-center"
                          height={50}
                          src={urlForImage(element.image).url()}
                          width={50}
                        />
                      ) : null}
                      <div className="flex flex-col justify-center md:ml-2">
                        <p
                          className={`text-sm md:text-[0.875rem] leading-5 font-light text-left hidden lg:inline ${
                            index === value ? 'text-primary' : 'text-black-80'
                          }`}
                        >
                          {t('faqs.faqsAbout')}
                        </p>
                        <p
                          className={`text-center lg:text-left leading-5 ${
                            index === value
                              ? 'text-primary font-semibold text-xs md:text-sm'
                              : 'text-black-base text-xs md:text-[0.875rem] font-normal md:font-semibold'
                          }`}
                        >
                          {element.label}
                        </p>
                      </div>
                    </div>
                  }
                  {...a11yProps(index)}
                  key={element._id}
                />
              );
            })}
          </Tabs>
        </div>
        <div className="flex flex-col mt-5 lg:mt-0 w-full">
          {data.groups?.map((element, i) => {
            return (
              <TabPanel index={i} key={element._id} value={value}>
                {element.label ? (
                  <h3 className="w-full pb-2 lg:pb-4 px-0 text-2xl md:text-[2rem] text-black font-display font-normal leading-8 md:leading-7 text-center md:text-left">
                    {t('faqs.faqs')}&nbsp;{element.label}
                  </h3>
                ) : null}
                {element.faqs?.map((faq, index) => {
                  const currentPanel = `panel${index}`;
                  const isExpanded = expanded === currentPanel;
                  return (
                    <Accordion expanded={isExpanded} key={faq._id} onChange={handleChangeExpanded(currentPanel)}>
                      <AccordionSummary
                        aria-controls={`${currentPanel}-content`}
                        className="px-0 focus-visible:bg-accent-2"
                        expandIcon={
                          isExpanded ? (
                            <MinusCircleIcon className="pr-3 w-8 h-5 text-primary" />
                          ) : (
                            <PlusCircleIcon className="pr-3 w-8 h-5 text-primary" />
                          )
                        }
                        id={`${currentPanel}-header`}
                      >
                        <div className="w-full text-base font-medium">{faq.question}</div>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          paddingLeft: '2rem',
                        }}
                      >
                        <div className="text-sm">
                          <PortableText value={faq.answer} />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </TabPanel>
            );
          })}
        </div>
      </div>
    </section>
  );
}
