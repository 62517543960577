import Image from 'next/image';
import Button from '@mui/material/Button';
import { PortableText } from '@portabletext/react';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    bgColor: string;
    headline: string;
    subheadline: [];
    ctaText: string;
    ctaHref: string;
    image: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    variant: 'textFirst' | 'imageFirst';
    width: 'full' | 'narrow';
  };
};

type ImageBlockProps = {
  headline: string;
  href: string;
  lqip: string;
  width?: 'narrow' | 'full';
};

function ImageBlock({ headline, href, lqip, width }: ImageBlockProps): JSX.Element {
  return (
    <div
      className={`relative w-full ${width === 'narrow' ? 'h-[clamp(11.875rem,35vw,17.625rem)]' : 'h-[clamp(11.875rem,35vw,24rem)]'}`}
    >
      <Image
        alt={headline}
        blurDataURL={lqip}
        className="rounded-lg"
        fill
        placeholder="blur"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        src={href}
        style={{
          objectFit: 'cover',
        }}
      />
    </div>
  );
}

type TextBlockProps = {
  headline: string;
  subheadline: [];
  ctaText: string;
  ctaHref: string;
  width?: 'narrow' | 'full';
};

function TextBlock({ headline, subheadline, ctaText, ctaHref, width }: TextBlockProps): JSX.Element {
  return (
    <div className="flex flex-col md:justify-center w-full order-1 sm:order-none">
      <h3
        className={`pt-3 pb-2 text-2xl lg:text-[2rem] text-black font-display font-normal leading-[1.875rem] lg:leading-10 ${width === 'narrow' ? 'lg:py-3' : 'lg:py-8'}`}
      >
        {headline}
      </h3>
      <div className="pb-4 text-sm md:text-base font-light leading-5 lg:leading-6 text-black">
        <PortableText value={subheadline} />
      </div>
      {ctaText && ctaHref ? (
        <Button className="self-start" color="primary" disableElevation href={ctaHref} variant="contained">
          {ctaText}
        </Button>
      ) : null}
    </div>
  );
}

export default function TextAndImage({ data }: Props): JSX.Element | null {
  if (!data.headline || !data.image.asset._ref) {
    return null;
  }

  return (
    <section className={`flex w-full justify-center px-4 md:px-10 py-6 sm:py-8 lg:py-14 lg:px-16 ${data.bgColor}`}>
      <div
        className={`flex gap-x-8 lg:gap-x-14 flex-wrap justify-center items-center sm:flex-nowrap w-full ${data.width === 'narrow' ? 'max-w-[62.1875rem]' : 'max-w-screen-lg'}`}
      >
        {data.variant === 'textFirst' ? (
          <>
            <TextBlock
              ctaHref={data.ctaHref}
              ctaText={data.ctaText}
              headline={data.headline}
              subheadline={data.subheadline}
              width={data.width}
            />
            <ImageBlock
              headline={data.headline}
              href={urlForImage(data.image).url()}
              lqip={data.image.lqip}
              width={data.width}
            />
          </>
        ) : (
          <>
            <ImageBlock
              headline={data.headline}
              href={urlForImage(data.image).url()}
              lqip={data.image.lqip}
              width={data.width}
            />
            <TextBlock
              ctaHref={data.ctaHref}
              ctaText={data.ctaText}
              headline={data.headline}
              subheadline={data.subheadline}
              width={data.width}
            />
          </>
        )}
      </div>
    </section>
  );
}
