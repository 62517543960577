import Image from 'next/image';
import { PortableText } from '@portabletext/react';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    altText: string;
    headline?: [];
    subheadline?: [];
    alignment: string;
    largeImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    mobileImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  };
};

export default function Hero({ data }: Props): JSX.Element | null {
  const hasImage = data.headline || data.largeImage.asset._ref || data.mobileImage.asset._ref;
  if (!hasImage) {
    return null;
  }

  const largeImageUrl = urlForImage(data.largeImage).url();
  const mobileImageUrl = urlForImage(data.mobileImage).url();

  return (
    <section className="relative flex flex-col justify-center md:flex-row w-full bg-accent-3">
      <div className="relative w-full h-[50vw] min-h-[15rem] max-h-[32.8125rem] md:ml-auto">
        <picture className="block relative w-full h-full">
          <source media="(max-width: 960px)" srcSet={mobileImageUrl} />
          <source media="(min-width: 1200px)" srcSet={largeImageUrl} />
          <Image
            alt={data.altText}
            blurDataURL={data.largeImage.lqip}
            fill
            placeholder="blur"
            priority
            sizes="100vw"
            src={largeImageUrl}
            style={{
              objectFit: 'cover',
            }}
          />
        </picture>
      </div>
      <div className="flex absolute justify-center w-full min-h-[11rem] h-full top-0 px-4 py-6 md:pt-20 md:pb-16 md:px-8 lg:px-16">
        {data.headline || data.subheadline ? (
          <div
            className={`flex flex-col justify-center w-full h-full max-w-screen-lg ${data.alignment === 'center' ? 'items-center' : ''}${data.alignment === 'right' ? 'md:pl-[50%]' : ''}`}
          >
            {data.headline ? (
              <span className="w-full text-fluid-lg leading-fluid-lg font-display font-semibold text-white">
                <PortableText value={data.headline} />
              </span>
            ) : null}
            {data.subheadline ? (
              <div className="pt-6 pb-2 md:py-4 text-lg text-black md:text-white font-medium leading-6">
                <PortableText value={data.subheadline} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
}
