import { useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';

type Props = {
  className?: string;
  translationsDictionary: TranslationsDictionary;
};

export default function Coupon({ className, translationsDictionary }: Props): JSX.Element {
  const [isCouponCodeCopied, setIsCouponCodeCopied] = useState(false);
  const [error, setError] = useState('');
  const t = useTranslation(translationsDictionary);

  function handleCopyToClipboard(): void {
    navigator.clipboard
      .writeText(t('coupon.code'))
      .then(() => {
        setIsCouponCodeCopied(true);
        setTimeout(() => {
          setIsCouponCodeCopied(false);
        }, 4000);
      })
      .catch(() => {
        setError(t('coupon.copyError'));
      });
  }

  return (
    <div
      className={`flex flex-col items-center justify-center gap-x-4 w-full h-full p-2 pt-0 sm:pt-2 rounded-b-lg sm:rounded-lg bg-white sm:bg-transparent sm:mb-0 sm:pb-0 ${className}`}
    >
      <p className="flex items-center text-nowrap justify-center text-center text-[0.8125rem] leading-[1.1875rem] p-0">
        <strong>{t('coupon.copyTheCode')}</strong>
        <span className="ml-1">{t('coupon.useIt')}</span>
      </p>
      <Tooltip className="flex" open={isCouponCodeCopied} placement="top" title="Copiado al portapapeles">
        <div className="flex flex-row items-center justify-center gap-2 w-full h-full p-2 rounded-lg">
          <Button
            className="px-3 py-1 text-base leading-[1.625rem] bg-white text-black border-dashed border-primary border-[1px] rounded-lg hover:text-white"
            disableElevation
            onClick={handleCopyToClipboard}
            variant="contained"
          >
            {t('coupon.code')}
          </Button>
          <Button
            className={`px-4 py-1 text-sm leading-[1.625rem] font-semibold ${isCouponCodeCopied ? 'text-[#3A8B3C]' : ''}`}
            disableElevation
            onClick={handleCopyToClipboard}
            startIcon={isCouponCodeCopied ? <CheckIcon /> : <ContentCopyOutlinedIcon />}
            variant="text"
          >
            {isCouponCodeCopied ? t('coupon.copied') : t('coupon.copy')}
          </Button>
          {error ? <p className="text-xs text-red-500">{error}</p> : null}
        </div>
      </Tooltip>
    </div>
  );
}
